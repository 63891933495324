import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Toaster } from '@components/old/Toaster/Toaster';
import { Loader } from '@components/old/Loader';
import { SocketMessage, UserModel } from '@models';
import { useAuth0 } from '@auth0/auth0-react';
import { me } from '@api';
import { plainToInstance } from 'class-transformer';
import { userModelStore } from '@stores';
import { SetterOrUpdater, useSetRecoilState, useRecoilValue } from 'recoil';
import {
  Header, Footer, AuthModal, Loading,
} from '@components';
import styled from '@emotion/styled';
import { io } from 'socket.io-client';
import { SOCKET_URL } from '@helpers/socket';
import { SocketMessageType } from '@enums';
import { useMitt } from 'react-mitt';
import { useResponsive } from '@hooks/useResponsive';
import { ReferralModal } from '@components/modals/ReferralModal';

const TRACKING_ID = 'G-P6TM514VQF';
ReactGA.initialize(TRACKING_ID);

export const App = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const setUser: SetterOrUpdater<UserModel | null> = useSetRecoilState(userModelStore);
  const user: UserModel | null = useRecoilValue(userModelStore);
  const { emitter } = useMitt();
  const { isMobile, isTablet } = useResponsive();

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    (async () => {
      if (isAuthenticated) {
        const token: string = await getAccessTokenSilently();
        const socket = io(SOCKET_URL, {
          auth: {
            token,
          },
        });
        socket.on(
          SocketMessageType.audiocraftStarted,
          (data: SocketMessage) => {
            emitter.emit(SocketMessageType.audiocraftStarted, data);
          },
        );
        socket.on(
          SocketMessageType.audiocraftProgress,
          (data: SocketMessage) => {
            emitter.emit(SocketMessageType.audiocraftProgress, data);
          },
        );
        socket.on(
          SocketMessageType.audiocraftTerminated,
          (data: SocketMessage) => {
            emitter.emit(SocketMessageType.audiocraftTerminated, data);
          },
        );
        socket.on(SocketMessageType.demucsStarted, (data: SocketMessage) => {
          console.log('demucsStarted', data);
          emitter.emit(SocketMessageType.demucsStarted, data);
        });
        socket.on(SocketMessageType.demucsProgress, (data: SocketMessage) => {
          console.log('demucsProgress', data);
          emitter.emit(SocketMessageType.demucsProgress, data);
        });
        socket.on(SocketMessageType.demucsTerminated, (data: SocketMessage) => {
          console.log('demucsTerminated', data);
          emitter.emit(SocketMessageType.demucsTerminated, data);
        });
        return () => socket.disconnect();
      }
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location]);

  useEffect(() => {
    if (isAuthenticated) {
      me(getAccessTokenSilently)
        .then((oktaUser: UserModel) => {
          setUser(
            plainToInstance(UserModel, {
              id: oktaUser.id,
              email: oktaUser.email,
              active: oktaUser.active,
              audiocraftModelSettings: oktaUser.audiocraftModelSettings,
              defaultSegmentDuration: oktaUser.defaultSegmentDuration,
              permissions: oktaUser.permissions,
            }),
          );
        })
        .catch((err) => {
          console.error(err.message);
        });
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <AppWrapper>
      {isAuthenticated && user && !user?.active ? <ReferralModal /> : null}
      {!isAuthenticated ? <AuthModal /> : null}
      <Toaster />
      <Header />
      <AppContent>
        <Outlet />
      </AppContent>
      {isMobile || isTablet ? null : <Footer />}
      <Loader />
    </AppWrapper>
  );
};

const AppWrapper = styled.div({
  margin: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'transparent',
});

const AppContent = styled.div({
  padding: '5rem 2rem 3rem 2rem',
  height: '100vh',
  position: 'relative',
});
