import {
  // GradientElipsis,
  Button,
  BottomDrawer,
  LibraryList,
  IconButton,
} from '@components';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as ArrowDownGradient } from '@assets/svgs/arrowDownGradient.svg';
import { ReactComponent as ArrowDownWhite } from '@assets/svgs/arrowDownWhite.svg';
import { SessionModel } from '@models';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { globalStore, GlobalStore, sessionsModelStore } from '@stores';
import { useResponsive } from '@hooks/useResponsive';
import { useAuth0 } from '@auth0/auth0-react';
import { getSessionsApi } from '@api';
import Add from '@mui/icons-material/Add';

interface ButtonContainerProps {
  smallScreens: boolean;
}

interface PageContainerProps {
  children: React.ReactNode;
  newSongButton?: boolean;
}

interface ButtonWithArrowProps {
  isSmallScreen: boolean;
  newSongButton?: boolean;
  libraryOpen: boolean;
  setLibraryOpen: (value: boolean) => void;
}

const ButtonWithArrow = ({
  isSmallScreen,
  newSongButton = false,
  libraryOpen,
  setLibraryOpen,
}: ButtonWithArrowProps) => {
  const globalStoreValue: GlobalStore = useRecoilValue(globalStore);
  const setGlobalStore: SetterOrUpdater<GlobalStore> = useSetRecoilState(globalStore);
  const onButtonClick = () => {
    setLibraryOpen(!libraryOpen);
    setGlobalStore({ ...globalStoreValue, footerVisible: !globalStoreValue.footerVisible });
  };

  return (
    <ButtonContainer smallScreens={isSmallScreen}>
      <ButtonsContainer>
        <LibraryButton
          size={isSmallScreen ? 'lg' : 'md'}
          type={libraryOpen ? 'shadow' : 'gradient'}
          onClick={onButtonClick}
          text={libraryOpen ? 'Close' : 'All my songs'}
        />
        {libraryOpen ? <WhiteArrowDownIcon /> : <GradientArrowDownIcon />}
      </ButtonsContainer>
      {newSongButton ? (
        <IconButtonContainer>
          <IconButton
            onClick={() => {
              window.location.href = '/';
            }}
            size={37}
            type="shadow"
            icon={<Add style={{ color: 'white' }} />}
          />
        </IconButtonContainer>
      ) : null}
    </ButtonContainer>
  );
};

export const PageContainer = ({
  children,
  newSongButton = false,
}: PageContainerProps) => {
  const {
    isMobile, isTablet, isSmallDesktop, isDesktop,
  } = useResponsive();
  const smallScreens = isMobile || isTablet;
  const [loading, setLoading] = useState(true);
  const [libraryOpen, setLibraryOpen] = useState(false);
  const sessions: SessionModel[] = useRecoilValue(sessionsModelStore);
  const setSessions: SetterOrUpdater<SessionModel[]> = useSetRecoilState(sessionsModelStore);

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const sortSessions = (sessions) =>
    sessions?.sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    );

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      try {
        getSessionsApi(getAccessTokenSilently)
          .then((sessions) => {
            const sortedSessions = sortSessions(sessions);
            setSessions(sortedSessions);
          })
          .then(() => setLoading(false));
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  }, [isAuthenticated, getAccessTokenSilently, setSessions]);

  const calculateColumns = () => {
    switch (true) {
      case isSmallDesktop:
        return 3;
      case isMobile:
        return 1;
      case isTablet:
        return 2;
      case isDesktop:
        return 4;
      default:
        return 1;
    }
  };

  return (
    <>
      <BottomDrawer
        libraryOpen={libraryOpen}
        setLibraryOpen={() => setLibraryOpen(!libraryOpen)}
      >
        <LibraryContainer>
          <LibraryList
            loading={loading}
            gap={20}
            columns={calculateColumns()}
            masonry
            closeDrawer={() => setLibraryOpen(false)}
            sessions={sessions}
          />
        </LibraryContainer>
      </BottomDrawer>

      <GlobalContainer>
        <div>{children}</div>
        <Container>
          <ButtonWithArrow
            isSmallScreen={smallScreens}
            newSongButton={newSongButton}
            libraryOpen={libraryOpen}
            setLibraryOpen={setLibraryOpen}
          />
        </Container>
      </GlobalContainer>
    </>
  );
};

const LibraryContainer = styled.div({
  width: '90%',
  margin: 'auto',
  marginBottom: '10rem',
});

const ButtonContainer = styled.div<ButtonContainerProps>(
  ({ smallScreens }) => ({
    position: 'fixed',
    bottom: smallScreens ? '0.2rem' : '0rem',
    flexDirection: 'row',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000,
  }),
);

const LibraryButton = styled(Button)({
  fontWeight: 600,
});

const GradientArrowDownIcon = styled(ArrowDownGradient)({
  width: '1rem',
  height: '1.5rem',
});

const WhiteArrowDownIcon = styled(ArrowDownWhite)({
  width: '1rem',
  height: '1.5rem',
});

const Container = styled.div({
  height: '100vh',
  maxWidth: '100vw',
  marginLeft: '-2rem',
  alignContent: 'center',
  justifyContent: 'center',
  display: 'flex',
});

const GlobalContainer = styled.div({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  height: '100vh',
  marginTop: '-5rem',
  zIndex: 1,
});

const ButtonsContainer = styled.div({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: 'white',
  zIndex: 10000,
});

const IconButtonContainer = styled.div({
  marginBottom: 25,
  marginLeft: 10,
});
